import { Component } from "@angular/core";
import { Const } from "@const/Const";
import { BaseDialog } from "@dialogs/base-dlg";
import { ApiService } from "@services/api.service";
import { InputHelper } from "@services/input-helper";
import { Log } from "@services/log";
import { ExfreightInfo } from '@wearewarp/types/rest-api/third-party/exfreight';

@Component({
  selector: '[exfreight-info]',
  templateUrl: './index.html',
  styleUrls: ['../../../dialogs/dialogs.scss', './index.scss']
})
export class ExfreightInfoDialog extends BaseDialog {
  order: any;
  inProgress = false;
  documents: Array<any> = [];
  exfreightInfo: ExfreightInfo

  get orderId() {return this.order?.id}
  get warpId() { return this.showOrderWarpId(this.order.warpId) };
  get isHaveJob() { return this.order?.jobIds?.length};
  get customerCost() {return InputHelper.formatMoney2(this.order?.cost?.grandTotal)}
  get carrierCost() {
    if (!this.exfreightInfo?.total_charge) {
      return;
    }
    let money = this.getMoneyDollar(this.exfreightInfo?.total_charge?.originalValue, this.exfreightInfo?.total_charge?.unit);
    return InputHelper.formatMoney2(money.toString())
  }
  get deliverDate() {
    if (this.exfreightInfo?.legs.length) {
      return this.exfreightInfo?.legs?.[this.exfreightInfo?.legs.length - 1]?.scheduled_delivery_date;
    }
  }
  get isBooked(): boolean {return !!this.exfreightInfo?.bookingResult}
  get txtTitle() {
    if (this.isBooked) {
      return `Order <code>${this.warpId}</code> has been booked with Exfreight`;
    } else {
      return `Book Exfreight for Order <code>${this.warpId}</code>`;
    }
  }

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getExfreightInfo();
    this.initDocuments();
  }

  private initDocuments() {
    if (this.exfreightInfo?.bookingResult) {
      this.documents = this.exfreightInfo?.bookingResult.documents.map(it => {return {link: it, fileName: this.getFileName(it)}})
    }
  }

  private getMoneyDollar(value: number, unit) {
    if (unit == Const.MonenyUnits.us_cent) {
      return Const.convertCentToDollar(value);
    }
    return value;
  }

  onBtnOk() {
    if (!this.isBooked) {
      this.book();
    }
  }

  onBtnCancel() {
    this.closeDialog();
  }

  private book() {
    this.inProgress = true;
    let url = `${Const.APIURI_ORDERS}/${this.orderId}/book_exfreight`;
    this.api.POST(url, {}).subscribe(
      resp => {
        Log.d('onBooking resp: ', resp);
        this.exfreightInfo.bookingResult = resp.data.bookingResult;
        this.order.displayInfo = {...this.order.displayInfo, status: resp.data.status, jobIds: resp.data.jobIds }
        this.initDocuments();
        this.inProgress = false;
      }, err => {
        this.showErr(err);
        this.inProgress = false;
      }
    );
  }

  private getExfreightInfo() {
    if (this?.order?.exfreightInfo) {
      this.exfreightInfo = this.order.exfreightInfo;
    } else {
      this.inProgress = true;
      let url = `${Const.APIURI_ORDERS}/${this.orderId}/third-party-info?source=${Const.thirdPartyOrderSource.Exfreight}`;
      this.api.GET(url, {}).subscribe(
        resp => {
          Log.d('getExfreightInfo: ', resp);
          this.exfreightInfo = resp.data.info;
          this.initDocuments();
          this.inProgress = false;
        }, err => {
          this.showErr(err);
          this.inProgress = false;
        }
      );
    }
  }

  getFileName(path: string) {
    let arr = path.split('/');
    return arr[arr.length - 1];
  }

  onClickDownload(item) {
    if (item.isDownloading) {
      return;
    }
    item.isDownloading = true;
    let url = `${Const.APIURI_ORDERS}/download-exfreight-document`;
    this.api.postExport(url, {filePath: item.link}).subscribe(
      resp => {
        ApiService.handleDownloadResponse(resp, item.fileName);
        item.isDownloading = false;
      }, err => {
        this.showErr(err);
        item.isDownloading = false;
      }
    );
  }


}