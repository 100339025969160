import { BaseComponent } from '@abstract/BaseComponent';
import { Component, Input } from '@angular/core';
import { DrawerService } from '@app/drawers/drawer.service';
import { InputHelper } from '@services/input-helper';
import { Utils } from '@services/utils';
import { ViewContentItem } from '../view-content-item';
import { ShipmentItem } from '@wearewarp/types/data-model';
import { BizUtil } from '@services/biz';
import { Const } from '@const/Const';
import { ShipmentItemStatus } from '@wearewarp/types';
import { DialogService } from '@dialogs/dialog.service';
import { PalletHistory } from '@app/admin/components/pallet-history';
@Component({
  selector: 'item-list',
  templateUrl: './view.html',
  styleUrls: ['./style.scss']
})
export class ItemList extends BaseComponent {

  public displayInfo: any = {};
  @Input() set items(value) {
    this.buildDisPlayInfo(value);
  }

  private buildDisPlayInfo(items: ShipmentItem[]) {
    this.displayInfo.items = items.map((item, index) => ({
      id: item.id,
      name: this.getItemName(item, index),
      qtyNumer: item.qty || 0,
      qtyTxt: this.getItemQty(item),
      totalWeight: this.getItemTotalWeight(item),
      weightPerUnit: this.getItemWeightPerUnit(item),
      size: this.getItemSize(item),
      itemValue: this.formatItemValue(item.itemValue),
      itemId: this.getItemId(item),
      barcodes: this.getItemBarcode(item),
      serivces: this.getItemService(item),
      isMissing: item.isMissing,
      status: this.getItemStatus(item.status),
      toJSON: item
    }));
  }

  formatItemValue(itemValue){
    return InputHelper.formatMoney2(itemValue);
  }

  getItemStatus(status: ShipmentItemStatus) {
    switch (status) {
      case Const.ItemStatus.pickupFailed:
        return 'Pickup Failed';
      case Const.ItemStatus.deliverFailed:
        return 'Deliver Failed';
      default:
        return '';
    }
  }

  getItemName(item: ShipmentItem, index: number): string {
    return item.name || ('Item ' + (index+1));
  }

  getItemQty(item: ShipmentItem): string {
    const quantity = item?.qty || this.naText
    const unit = item?.qtyUnit || this.naText
    return `${quantity} ${unit}`
  }

  getItemSize(item: ShipmentItem): string {
    return BizUtil.getItemSizeDesc(item);
  }

  getItemTotalWeight(item: ShipmentItem): string {
    const quantity = item?.qty || 1;
    const weight = item?.weightPerUnit ?? 0;
    const unit = item?.weightUnit ?? '';
    return `${Utils.roundNumber(weight * quantity, 0)} ${unit}`
  }

  getItemWeightPerUnit(item: ShipmentItem): string {
    const weight = item?.weightPerUnit ?? 0;
    const unit = item?.weightUnit ?? '';
    return `${weight} ${unit}`
  }

  getItemId(item: ShipmentItem){
    return item?.itemId || this.naText
  }

  getItemBarcode(item: ShipmentItem) {
    if (item.barcodes?.length > 1) {
      return `Barcodes (${item.barcodes.length}): ${item.barcodes?.join(', ')}`;
    } else if (item.barcodes?.length == 1) {
      return `Barcode: ${item.barcodes[0]}`;
    } else {
      return `Barcode: ${this.naText}`;
    }
  }

  getItemService(item: ShipmentItem){
    let str = BizUtil.getItemServiceDesc(item);
    return str || this.naText
  }

  viewContent(item, index: number){
    DrawerService.openDrawer(ViewContentItem, {
      nzClosable: true,
      nzKeyboard: false,
      nzContent: null,
      // nzTitle: `${item.name ?? "Item"} Contents`,
      nzTitle: 'Item Contents',
      nzContentParams: {
        item: item,
        itemIndex: index,
      },
      nzWidth: 600,
    });
  }

  private get naText(){
    return "N/A"
  }

  onReportMissing(item) {
    this.modalService.create({
      nzTitle: 'Report Missing',
      nzContent: `
      ${item.name}, ${item.qtyTxt}, ${item.totalWeight}, ${item.size} <br/><br/>
      ${item.barcodes} <br/><br/>
      Are you sure you want to mark this item is missing?
      `,
      nzClosable: false,
      nzMaskClosable: false,
      nzCentered: true,
      nzOkText: 'OK',
      nzOnOk: () => this.confirmMarkMissing(item.id),
      nzCancelText: 'Cancel'
    });  
  }

  private confirmMarkMissing(itemId) {
    const url = Const.APIV2(`shipment_items/${itemId}/missing`)
    this.api.PUT(url, { isMissing: true }).subscribe((res) => {
      this.displayInfo.items = this.displayInfo.items.map(item => {
        if (item.id === itemId) {
          item.isMissing = true
          item.status = this.getItemStatus(res.data?.status)
        }
        return item;
      });
    }, err => {
      this.showErr(err);
    })
  }

  public historyItem: ShipmentItem;
  public get historyItemWarpId() {
    return this.historyItem?.warpId;
  }
  setHistoryItem(item: ShipmentItem){
    this.historyItem = item;
  }

  onViewItemsHistory(item: ShipmentItem){
    DialogService.openDialog(PalletHistory, {
        nzComponentParams: {
            itemId: item.id
        },
        nzWidth: '630px',
        nzStyle:{
            top: '20px'
        },
        nzTitle: `Warp ID: ${item?.warpId} - Item history`
    })
  }
}
