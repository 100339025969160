import { BaseComponent } from "@abstract/BaseComponent";
import { Component } from "@angular/core";
import { Input } from "@angular/core";
import { environment } from "@env/environment";
import { Utils } from "@services/utils";
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
    selector: 'dlg-clone-order-success',
    templateUrl: './view.html',
    styleUrls: ['../../../../dialogs/dialogs.scss', '../../../../app.scss']
}) 
export class DlgCloneOrderSuccess extends BaseComponent {

  @Input() orders: any[] = [];
  @Input() onSuccess: () => void = () => {};

  constructor(private modal: NzModalRef) {
      super();
    }
  
  private _onProgress = false;

  get onProgress() {
      return this._onProgress;
  }

  public closeDialog() {
    if (this.modal) {
      this.modal.destroy();
    }
  }

  onDone() {
    this.closeDialog();
    this.onSuccess();
  }

  getTrackingLink(order) {
    return `${environment.trackingWebUrl}/${order.code ?? order.trackingCode}`;
  }

  copyTrackingLink(order) {
    let content = this.getTrackingLink(order);

    Utils.copyTextToClipboard(content, () => {
      this.notification.success('Copy Tracking Link', 'Tracking Link has been copied to clipboard')
    })
  }

} 