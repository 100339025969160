<div nz-row [nzGutter]="{ md: 16 }">
    <div *ngIf="pickup" nz-col [nzMd]="shouldShowRevenue ? 9 : 12" nzSm="12" nzXs="24">
        <div shipment-delivery-info (onUpdated)="onUpdated()" [editMode]="editMode" [shipment]="shipment" [info]="pickup" [isShowActualTime]="isShowActualTime"></div>
    </div>
    <div *ngIf="dropoff" nz-col [nzMd]="shouldShowRevenue ? 9 : 12" nzSm="12" nzXs="24">
        <div shipment-delivery-info (onUpdated)="onUpdated()" [editMode]="editMode" [shipment]="shipment" [info]="dropoff" [isShowActualTime]="isShowActualTime"></div>
    </div>
    <!-- <div nz-col nzMd="6" nzSm="6" nzXs="24" *ngIf="shouldShowRevenue">
        <div class="delivery-info-container">
            <span class="info-name-color-box REVENUE">Revenue</span>
            <div shipment-revenue [editMode]="editMode" [cost]="shipment.cost" [shipmentId]="shipment.id" (onCostUpdate)="onCostUpdate($event)"></div>
        </div>        
    </div> -->
</div>


<div class="label">
  <span>Items</span>
  <span>
    <a class="edit-item-shipment-entry" (click)="onBtnEditItems()">
      <i nz-icon nzType="edit" nzTheme="outline" style="margin-right: 5px;"></i>Edit
    </a>
  </span>
  <span *ngIf="canUploadItemManifest">
    <a class="edit-item-shipment-entry" (click)="onBtnUploadItemsManifest()">
      <i nz-icon nzType="upload" nzTheme="outline" style="margin-right: 5px;"></i>Upload Manifest
    </a>
  </span>
</div>
<item-list [items]="items"></item-list>
