import { Component, Input } from "@angular/core";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { ApiService } from "@services/api.service";
import { Const } from "@const/Const";
import { DateUtil } from "@services/date-utils";
import { BizError } from "@wearewarp/universal-libs";
import { Log } from "@services/log";
import { Utils } from "@services/utils";
import { DlgCloneOrderSuccess } from "@app/admin/shipments/clone-shipment/dlg-clone-success";

@Component({
  selector: '[shipment-pickup-date]',
  templateUrl: './index.html',
  styleUrls: ["../../../../../dialogs/dialogs.scss", "../../../../../../styles/row-col.scss", "./index.scss"]
})
export class ShipmentPickupDate extends BaseFormDialog1 {

  @Input() orderData;

  constructor(protected api: ApiService) {
    super();
  }

  protected formGroupDeclaration: FormGroupDeclaration = {
    date: {label: 'Pickup Date', type: 'date', required: true, isChanged: DateUtil.diffYYYYMMDD},
    isCloneRoute: {label: 'Clone Route', type: 'boolean', required: false, initialValue: true},
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  onBtnSave() {
    let shiftDays = this.getShiftDays();
    let url = `${Const.APIV2('orders')}/clone1`;
    this.setEnableFormGroup(false);
    this.startProgress();
    this.api.POST(url, { orderId: this.orderData?.id, shiftDays }, { observe: "response" }).subscribe(
      (resp) => {
        let httpCode = resp.status;
        let body = resp.body;
        Log.d(`createData done, statusCode: ${httpCode}, resp: `, body);
        let includeCloneRoute = this.getItemValue('isCloneRoute');
        if(includeCloneRoute) {
          let newOrderId = body?.data?.id;
          let oldOrderId = this.orderData?.id;
          let url2 = `${Const.APIV2('jobs')}/clone-route-for-order`;
          this.api.POST(url2, { newOrderId, oldOrderId }, { observe: "response" }).subscribe({
            next: (resp) => {
              Log.d(`createRoute done, resp: `, resp);
              this.onCreateSuccess(body);
              this.stopProgress();
            },
            error: (err) => {
              Log.e("createRoute error: ", err);
            }
          });
        }
        else {
          this.onCreateSuccess(body);
          this.stopProgress();
        }
      },
      (err) => {
        Log.e("createData error: ", err);
        if(err?.data?.moduleErrorCode === BizError.ClientCreditLimitExceeded) {
          this.showErrDialog(`The customer credit has exceeded the limit. Please check your customer settings.`);
        } else {
          this.showErr(err);
        }
        this.stopProgress();
        this.setEnableFormGroup(true);
      }
    );
  }

  getDateInfo() {
    const shipment = this.orderData?.metadata?.shipments?.[0];
    if (!shipment) return null;
    const pickInfo = shipment.deliveryInfos.find(di => di.type == Const.TaskType.PICKUP);
    let { windows, appointmentInfo, addr } = pickInfo;
    const pickupTimeZone = addr?.metadata?.timeZoneStandard;
    let fromTime: any;
    if (windows && windows[0]) fromTime = windows[0].from;
    if (appointmentInfo && appointmentInfo?.from) fromTime = appointmentInfo.from;
    fromTime = DateUtil.convertLocalTime2(fromTime, pickupTimeZone);
    return fromTime;
  }

  getShiftDays() {
    let pickupDate = this.getItemValue('date');
    let dateInfo = this.getDateInfo();
    pickupDate = DateUtil.toBeginOfDay(new Date(pickupDate));
    dateInfo = DateUtil.toBeginOfDay(dateInfo);
    const diffInMilliseconds = pickupDate.getTime() - dateInfo.getTime();
    const diffInDays = diffInMilliseconds / (1000 * 3600 * 24);
    return Math.round(diffInDays);
  }

  protected onCreateSuccess(resp) {
    let order = resp?.data;
    if (Utils.isObjectNotEmpty(order)) {
      this.showDialogCloneComplete([{ id: order.id, warpId: order.warpId, trackingCode: order.trackingCode, code: order?.code }]);
    } else {
      this.createSuccess(resp);
    }
    if (this.closeOnSuccess) {
      this.closeDialog();
    }
  }

  private showDialogCloneComplete(orders) {
    this.modalService.create({
      nzContent: DlgCloneOrderSuccess,
      nzFooter: null,
      nzClosable: false,
      nzMaskClosable: false,
      nzKeyboard: false,
      nzComponentParams: { 
        orders
      }
    });
  }
  
}