import { BaseComponent } from "@abstract/BaseComponent";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ModelOrderDetail } from "@app/interfaces/order";
import { Const } from "@const/Const";

@Component({
  selector: "shipment-entry-batch-detail",
  templateUrl: "./view.html",
  styleUrls: ["./style.scss", "../../../../dialogs/dialogs.scss"],
})
export class ShipmentEntryBatchDetail extends BaseComponent {

  @Input() model: ModelOrderDetail;
  @Output() refreshDetailOrder: EventEmitter<any> = new EventEmitter<any>();
  public isTurnOnEditMode = Const.defaultTurnOnEditMode;

  get listShipments() { return this.model?.metadata?.shipments ?? [] }

  get shouldShowListShipemts() {
    return <string>this.model.shipmentEntryMode == 'multiPickDrop_LTL';
  }

  get shouldShowRoute() {
    return <string>this.model.shipmentEntryMode != 'multiPickDrop_LTL';
  }

  ngOnInit(): void {
  }

  onEditModeChange(event) {
    this.isTurnOnEditMode = !!event;
  }

  onBtnRefreshDetailOrder() {
    this.refreshDetailOrder.emit();
  }
  
}
