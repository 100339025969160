import { Const } from '@const/Const';
import { ResponseAdminOrderDetail } from "@wearewarp/types/rest-api/admin";
import { ModelOrderDetail, ModelOrderDetailLocation } from "@app/interfaces/order";
import { WarpId } from '@wearewarp/universal-libs';

export class ShipmentEntryGroupTasksService {
  public static getListLocationFollowJobTasks(modelOrder: ModelOrderDetail): ModelOrderDetailLocation[] {
    const shipments = modelOrder.metadata?.shipments || [];
    const job = shipments?.[0]?.jobs?.[0];
    const tasks = job?.tasks || [];
    const taskGroups = ShipmentEntryGroupTasksService.groupTasksV2(tasks);
    let locationGroups = taskGroups.map(tasks => {
      const shipmentIdsOfTask = tasks.map(task => task.shipmentId);
      const shipmentsOfTask = shipments.filter(sh => shipmentIdsOfTask.includes(sh.id));
      const firstTask = tasks?.[0] || {};
      if (firstTask.type == Const.TaskType.PICKUP) {
        return shipmentsOfTask.map(shipment => {
          const infos = shipment.deliveryInfos || [];
          const pickInfo = infos.filter(item => item.type == Const.TaskType.PICKUP)[0];
          const taskInfo = tasks.filter(task => task.shipmentId == shipment.id)[0];
          return { ...pickInfo, shipmentId: shipment.id, warpId: shipment.warpId, taskInfo: taskInfo}
        })
      } else if (firstTask.type == Const.TaskType.DROPOFF) {
        return shipmentsOfTask.map(shipment => {
          const infos = shipment.deliveryInfos || [];
          const dropInfo = infos.filter(item => item.type == Const.TaskType.DROPOFF)[0];
          const taskInfo = tasks.filter(task => task.shipmentId == shipment.id)[0];
          return { ...dropInfo, shipmentId: shipment.id, warpId: shipment.warpId, taskInfo: taskInfo}
        })
      }
    });
    locationGroups = locationGroups.filter(item => item);
    const mapShipments = ShipmentEntryGroupTasksService.getMapShipments(shipments);
    return locationGroups.map((locations, index) => {
      const deliveryIds = locations.map(item => item.id);
      const shipmentIds = locations.map(item => item.shipmentId);
      const shipmentWarpIds = locations.map(item => mapShipments[item.shipmentId]?.warpId);
      const shipmentCodes = locations.map(item => WarpId.showShipmentCode(mapShipments[item.shipmentId]));
      const tasksStatus = locations.map(item => {
         return { type: item.type, status: item.taskInfo?.status, warpId: item.warpId, shipmentId: item.shipmentId }
      })
      const locationShipments = shipments.filter(sh => shipmentIds.includes(sh.id));
      let items = [];
      let bolInfo;
      for (let shipment of locationShipments) {
        const itemIds : any[] = shipment.itemIds ?? [];
        let locationItems = modelOrder.items?.filter(it => itemIds.includes(it.id));
        items = [ ...items, ...locationItems ];
        bolInfo = {_id: shipment.bolFileId, type: 'application/pdf'};
      }
      const firstLocation = locations[0] || {};
      return {
        ...firstLocation,
        index,
        deliveryIds,
        shipmentIds,
        shipmentWarpIds,
        shipmentCodes,
        items,
        tasksStatus,
        bolInfo,
      }
    });
  }

  private static getMapShipments(shipments) {
    let mapShipments = {};
    shipments.map(item => { mapShipments[item.id] = item });
    return mapShipments
  }
  
  //kiểm tra cùng location và name
  private static isSameGroupnName(item1, item2): boolean {
    let l1 = item1.location;
    let l2 = item2.location;
    let l1_name = item1?.info?.locationName
    let l2_name = item2?.info?.locationName
    let isSameLocation = l1 && l2 && l1.latitude == l2.latitude && l1.longitude == l2.longitude && l1_name == l2_name;
    let isSameType = item1.type == item2.type;
    return isSameLocation && isSameType;
  }

  // chỉ dùng cho display fix tạm bug ở shipment, không sửa hàm groupTasks vì ở nhiều chỗ đang dùng
  private static groupTasksV2(tasks) {
    let arr = [];
    for (let item of tasks) {
      if (arr.length == 0) {
        arr.push([]);
      }
      let lastGroup = arr[arr.length - 1];
      if (lastGroup.length == 0) {
        lastGroup.push(item);
      } else {
        let lastItem = lastGroup[lastGroup.length - 1];
        if (ShipmentEntryGroupTasksService.isSameGroupnName(item, lastItem)) {
          lastGroup.push(item);
        } else {
          arr.push([item]);
        }
      }
    }
    return arr;
  }

  // Gom những task có cùng location vào 1 group
  private static groupTasks(tasks) {
    let arr = [];
    for (let item of tasks) {
      if (arr.length == 0) {
        arr.push([]);
      }
      let lastGroup = arr[arr.length - 1];
      if (lastGroup.length == 0) {
        lastGroup.push(item);
      } else {
        let lastItem = lastGroup[lastGroup.length - 1];
        if (ShipmentEntryGroupTasksService.isSameGroup(item, lastItem)) {
          lastGroup.push(item);
        } else {
          arr.push([item]);
        }
      }
    }
    return arr;
  }

  private static isSameGroup(item1, item2): boolean {
    let l1 = item1.location;
    let l2 = item2.location;
    let isSameLocation = l1 && l2 && l1.latitude == l2.latitude && l1.longitude == l2.longitude;
    let isSameType = item1.type == item2.type;
    return isSameLocation && isSameType;
  }

  public static getListLocationFollowSortedTaskIds(modelOrder: ResponseAdminOrderDetail) {
    const shipments = modelOrder.metadata?.shipments || [];
    let deliveryInfos = [];
    for (let shipment of shipments) {
      let infos = shipment.deliveryInfos || [];
      infos = infos.map((item) => {return { ...item, shipmentId: shipment.id}});
      deliveryInfos = [ ...deliveryInfos, ...infos ];
    }
    const mapShipments = ShipmentEntryGroupTasksService.getMapShipments(shipments);
    const sortedTaskIds = modelOrder.sortedTaskIds || [];
    return sortedTaskIds.map((groupIds, index) => {
      const locations = deliveryInfos.filter(item => groupIds.includes(item.id));
      const shipmentIds = locations.map(item => item.shipmentId);
      const shipmentWarpIds = locations.map(item => mapShipments[item.shipmentId]?.warpId);
      const shipmentCodes = locations.map(item => WarpId.showShipmentCode(mapShipments[item.shipmentId]));
      const locationShipments = shipments.filter(sh => shipmentIds.includes(sh.id));
      let items = [];
      let bolInfo;
      for (let shipment of locationShipments) {
        const itemIds : any[] = shipment.itemIds ?? [];
        let locationItems = modelOrder.items?.filter(it => itemIds.includes(it.id));
        items = [ ...items, ...locationItems ];
        bolInfo = {_id: shipment.bolFileId, type: 'application/pdf'};
      }
      const firstLocation = locations[0] || {};
      return {
        ...firstLocation,
        index,
        deliveryIds: groupIds,
        shipmentIds,
        shipmentWarpIds,
        shipmentCodes,
        items,
        bolInfo,
      }
    })
  }

  public static getListLocation_NotNeedSort(modelOrder: ResponseAdminOrderDetail) {
    const shipments = modelOrder.metadata?.shipments || [];
    let pickInfos = [];
    let dropinfos = [];
    for (let shipment of shipments) {
      let infos = shipment.deliveryInfos || [];
      infos = infos.map((item) => {return { ...item, shipmentId: shipment.id}});
      const bolInfo = {_id: shipment.bolFileId, type: 'application/pdf'};
      const itemIds : any[] = shipment.itemIds ?? [];
      let locationItems = modelOrder.items?.filter(it => itemIds.includes(it.id));
      let items = [ ...(locationItems || []) ];
      for (let info of infos) {
        if (info.type == Const.TaskType.PICKUP) {
          pickInfos.push({
            ...info,
            deliveryIds: [info.id],
            shipmentIds: [shipment.id],
            shipmentWarpIds: [shipment.warpId],
            shipmentCodes: [WarpId.showShipmentCode(shipment)],
            bolInfo,
            items,
          });
        }
        if (info.type == Const.TaskType.DROPOFF) {
          dropinfos.push({
            ...info,
            deliveryIds: [info.id],
            shipmentIds: [shipment.id],
            shipmentWarpIds: [shipment.warpId],
            shipmentCodes: [WarpId.showShipmentCode(shipment)],
            bolInfo,
            items,
          });
        }
      }
    }
    return [ ...pickInfos, ...dropinfos ]
  }
 
}