<div class="form-detail">
  <div class="f16m bottom20" [innerHTML]="txtTitle"></div>
  <div class="flex bottom5">
    <div class="label">Revenue:</div>
    <div class="flex1">{{customerCost}}</div>
  </div>
  <div class="flex bottom5">
    <div class="label">Cost:</div>
    <div class="flex1">{{carrierCost}}</div>
  </div>
  <div class="flex bottom5">
    <div class="label">Scheduled delivery:</div>
    <div class="flex1">{{deliverDate}}</div>
  </div>

  <ng-container *ngIf="isBooked">
    <div class="flex bottom5">
      <div class="label">Billing code:</div>
      <code class="flex1">{{exfreightInfo?.bookingResult?.billing_code}}</code>
    </div>
    <div class="flex">
      <div class="label">Documents:</div>
      <div class="flex1">
        <div *ngFor="let item of documents">
          <a (click)="onClickDownload(item)">{{item.fileName}}</a>
          <span *ngIf="item.isDownloading" style="margin-left: 10px" nz-icon nzType="loading" nzTheme="outline"></span>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<div form-footer [onProgress]="inProgress" 
  [nzIconCancel]="''" [canClickCancel]="!inProgress" (onCancel)="onBtnCancel()"
  [nzIconOK]="''" [isVisibleOK]="!isBooked" [canClickOK]="!inProgress && !isHaveJob" [labelOK]="'Book'" (onOK)="onBtnOk()">
</div>