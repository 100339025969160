import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Const } from '@const/Const';
import { DialogService } from '@dialogs/dialog.service';
import { DeliveryInfo, Shipment } from '@wearewarp/types/data-model';
import { EditShipmentEntryItems } from '../../edit-shipment/items';
import { WarpId } from '@wearewarp/universal-libs';
import { FormDataOrderItems } from '@app/admin/shipment-entry/interface';
import { ApiService } from '@services/api.service';
import { UploadItemsManifest } from '../../edit-shipment/items/upload-manifest';
import { CLIENT_IDS } from '@const/Client';

@Component({
  selector: '[shipment-list-item]',
  templateUrl: './view.html',
  styleUrls: ['./style.scss']
})
export class ShipmentListItem implements OnInit {
  private _shipment: Shipment;
  canUploadItemManifest: boolean = false
  @Input() get shipment(): Shipment { return this._shipment }
  set shipment(value: Shipment) {
    this._shipment = value;
    this.pickup = (this.shipment?.deliveryInfos || []).filter(it => it.type === Const.TaskType.PICKUP)[0];
    this.dropoff = (this.shipment?.deliveryInfos || []).filter(it => it.type === Const.TaskType.DROPOFF)[0];
    this.canUploadItemManifest = value.clientId == CLIENT_IDS.SAKS && value.shipmentType == 'FTL'; // Hack. TODO: config
  }

  @Output() shipmentUpdated: EventEmitter<void> = new EventEmitter<void>();
  @Input() items = [];

  shouldShowRevenue = false;
  editMode = true;
  isShowActualTime = false;
  pickup: DeliveryInfo;
  dropoff: DeliveryInfo;

  ngOnInit(): void {
  }

  onUpdated() {
    this.shipmentUpdated.emit();
  }

  onCostUpdate(event) {
    // TODO: not implemented
  }

  onBtnEditItems() {
    const shipmentId = this.shipment.id;
    DialogService.openDialog(EditShipmentEntryItems, {
      nzComponentParams: {
        shipmentWarpId: WarpId.showShipmentCode(this.shipment),
        modelItems: this.items,
        onSave: data => this.saveItems(shipmentId, data),
        onRefreshDetailOrder: () => this.itemUpdated()
      },
      nzClassName: "modal-xxl",
    });
  }

  onBtnUploadItemsManifest() {
    DialogService.openDialog(UploadItemsManifest, {
      nzComponentParams: {
        shipmentWarpId: WarpId.showShipmentCode(this.shipment),
        shipmentId: this.shipment.warpId,
        onSaved: (data) => this.items = data,
      },
    });
  }

  private saveItems(shipmentId, data: FormDataOrderItems) {
    const url = Const.APIV2(`${Const.APIURI_SHIPMENTS}/${shipmentId}/items`);
    return ApiService.instance.PUT(url, data);
  }

  private itemUpdated() {
    // TODO: not implemented
  }

}